import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Request } from '../../hooks/Request'
import { Texto, H3 } from '../../components/Text'
import { Button } from '../../components/Buttons'
import { Circle } from '../../components/Animation'
import { useDialog } from '../../contexts/DialogContext'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'

const ListLegalRepresentative = ({ company }) => {
  const { openDialog, dialogClose } = useDialog()
  const { data, loading, call } = Request({
    urlApi: `/company/${company.id}/legalRepresentative`,
  })

  function editContact(legalRep) {
    openDialog(
      <FormLegalRep
        legalRep={legalRep}
        companyId={company.id}
        reload={call}
        onClose={dialogClose}
      />,
    )
  }

  if (!loading) {
    return <Circle />
  }
  return (
    <div>
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] text-white flex flex-col shadow-xl">
        <div className="flex justify-between w-full mb-1">
          <Texto className="font-semibold text-black mr-3">
            Representante legal de la compañía
          </Texto>
          {/* <Button onClick={createLegalRep} className="px-2 py-1">
            <i className="fas fa-plus"></i>
          </Button> */}
        </div>
        {data.map((item) => (
          <div className="flex gap-1 mb-1">
            <div className="bg-[#ffffff65] text-black px-2 py-0.5 rounded-md  w-full">
              <h3>Nombre: {item.name}</h3>
              <h3>Ci: {item.ci}</h3>
              <h3>Lugar de exp.: {item.lugarCi}</h3>
            </div>
            <Button
              onClick={() => editContact(item)}
              className="px-2 py-1 bg-[#FBCE00]"
            >
              <i className="fas fa-edit"></i>
            </Button>
            {/* <Button
              onClick={() => deleteDropdown(item)}
              className="px-2 py-1 bg-[#eb4040]"
            >
              <i className="fas fa-trash"></i>
            </Button> */}
          </div>
        ))}
      </div>
    </div>
  )
}

const FormLegalRep = (props) => {
  const { legalRep, companyId, onClose, reload } = props
  return (
    <>
      <FormDefault
        tittle={legalRep ? 'Editar representante legal' : 'Crear nuevo representante legal'}
        fields={[
          {
            label: "Nombre del representante legal",
            name: "Name",
            placeholder: "Introduzca el código del cliente",
          },
          {
            label: "CI del representante legal",
            name: "CI",
            placeholder: "Introduzca el código del cliente",
          },
          {
            label: "Emisión del CI del representante legal",
            name: "LugarCi",
            placeholder: "Introduzca el código del cliente",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Santa Cruz",
                value: "Santa Cruz",
              },
              {
                label: "La Paz",
                value: "La Paz",
              },
              {
                label: "Cochabamba",
                value: "Cochabamba",
              },
              {
                label: "Chuquisaca",
                value: "Chuquisaca",
              },
              {
                label: "Oruro",
                value: "Oruro",
              },
              {
                label: "Tarija",
                value: "Tarija",
              },
              {
                label: "Beni",
                value: "Beni",
              },
              {
                label: "Pando",
                value: "Pando",
              },
              {
                label: "Potosí",
                value: "Potosí",
              },
              {
                label: "QR",
                value: "QR",
              },
            ],
          },
        ]}
        initialValues={{
          Name: legalRep?.name || "",
          CI: legalRep?.ci || "",
          LugarCi: legalRep?.lugarCi || "",
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required("Campo requerido"),
          CI: Yup.string().required("Campo requerido"),
          LugarCi: Yup.string().required("Campo requerido"),
        })}
        onSubmit={async (values) => {
          await requestAuth("post", "/company/legalrepresentative", {
            ...values,
            CompanyId: companyId,
          })
            .then(() => {
              onClose()
              reload()
              toast.success('Se creo con éxito')
            })
            .catch(() => {
              toast.error("Se ha producido un error al enviar");
            });
        }}
        buttonName={legalRep ? 'Editar' : 'Crear'}
      />
      <br />
    </>
  )
}

export default ListLegalRepresentative
