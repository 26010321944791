import { useState, useEffect } from "react";
import { Button } from "../Buttons";
import { H3, H1, H2, Label, Anchor } from "../Text";
import {
  InputFileStatus,
  ControlField,
  InputSubData,
  CompleteDocuments,
  InputItemsConta,
  InputSubDataAdd,
  InputSubContaAdd,
} from "../Inputs";
import Circle from "../Animation/Circle";
import DialogConfirmation from "../Modal/DialogConfirmation";
import CallServiceTable from "../Tables/CallServiceTable";
import { Request } from "../../hooks/Request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import SubModal from "../Modal/SubModal";
import ModalFullScreen from "../Modal/ModalFullScreen";
import { useDialog } from "../../contexts/DialogContext";
import Select from "react-select";
import { validateArray } from "../../utils/validate";
import CreatableSelect from "react-select/creatable";
import { MessageOnLabel } from "./FormMessages";
import SecondaryForm from "./SecondaryForm";
import InputDocumentsDataAdd from "../Inputs/InputDocumentsDataAdd";

const FormDefault = (props) => {
  const {
    initialValues,
    onSubmit,
    validationSchema,
    fields,
    buttonName,
    handleChange,
    tittle,
    bonusContent,
    classNameBtn,
    buttonFixed = false,
    buttonSecondary = false,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values, resetForm);
      }}
    >
      {(formik) => {
        const { isSubmitting, values, setValues } = formik;
        return (
          <Form className={`flex flex-col ${buttonFixed ? "" : ""}`}>
            {tittle !== undefined ? (
              <H1 className="my-0 mb-2">{tittle}</H1>
            ) : (
              ""
            )}
            {bonusContent}
            {fields?.map((field, i) => {
              const condition = field.condition
                ? (field.condition + "").split(",")
                : [];

              if (
                !field.conditionType ||
                (field.conditionType === "listValidate" &&
                  condition.indexOf(values[field.reference] + "") > -1) ||
                (field.conditionType === "notEmpty" && values[field.reference])
              ) {
                switch (field.typeInput) {
                  case "input":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <Input field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-medium text-lg mt-[5px] text-red-400 mx-auto"
                        />
                      </ContainerInp>
                    );
                  case "requestSerialNumber":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputSerial field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-medium text-lg mt-[5px] text-red-400 mx-auto"
                        />
                      </ContainerInp>
                    );
                  case "file":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputFile field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[16px] mt-[5px] text-red-400 mx-auto"
                        />
                      </ContainerInp>
                    );
                  case "fileMultiple":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputFile field={field} multiple />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[16px] mt-[5px] text-red-400 mx-auto"
                        />
                      </ContainerInp>
                    );
                  case "fileStatus":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputFileStatus field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[16px] mt-[5px] text-red-400 mx-auto"
                        />
                      </ContainerInp>
                    );
                  case "select":
                  case "selectGet":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <SelectInput field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[15px] mt-[5px] text-red-400"
                        />
                      </ContainerInp>
                    );
                  case "selectGetMultiple":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <SelectInputMultiple field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[15px] mt-[5px] text-red-400"
                        />
                      </ContainerInp>
                    );
                  case "subSelect":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        {values && values[field.fatherName] ? (
                          <SelectInput values={values} field={field} />
                        ) : null}
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[15px] mt-[5px] text-red-400"
                        />
                      </ContainerInp>
                    );
                  case "selectNewOption":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <SelectNewOption field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[15px] mt-[5px] text-red-400"
                        />
                      </ContainerInp>
                    );
                  case "selectTable":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <SelectTable field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[15px] mt-[5px] text-red-400"
                        />
                      </ContainerInp>
                    );
                  case "checkboxes":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <Checkboxes field={field} handleChange={handleChange} />
                      </ContainerInp>
                    );
                  case "fileExcel":
                  case "reviewExcel":
                  case "correctExcel":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <GenerateExcel
                          field={field}
                          handleChange={handleChange}
                        />
                      </ContainerInp>
                    );
                  case "controlField":
                  case "IncidentField":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <ControlField key={i} field={field} />
                      </ContainerInp>
                    );
                  case "controlFieldDelivery":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <ControlField
                          key={i}
                          field={field}
                          fieldDelivery={true}
                        />
                      </ContainerInp>
                    );
                  case "subItemsConta":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputItemsConta key={i} field={field} />
                      </ContainerInp>
                    );
                  case "subData":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputSubData key={i} field={field} />
                      </ContainerInp>
                    );
                  case "selectImage":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <SelectImage
                          key={i}
                          field={field}
                          values={values}
                          setValues={formik.setValues}
                        />
                      </ContainerInp>
                    );
                  case "subDataAdd":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputSubDataAdd key={i} field={field} />
                      </ContainerInp>
                    );
                  case "documentsData":
                  case "documentsDataAdd":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputDocumentsDataAdd key={i} field={field} />
                      </ContainerInp>
                    );
                  case "subDataContaAdd":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <InputSubContaAdd key={i} field={field} />
                      </ContainerInp>
                    );
                  case "completeDocuments":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <CompleteDocuments key={i} field={field} />
                      </ContainerInp>
                    );
                  case "completeDocumentsAdd":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <CompleteDocuments
                          key={i}
                          field={field}
                          addDocument={true}
                        />
                      </ContainerInp>
                    );
                  case "secondaryForm":
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <SecondaryForm
                          key={i}
                          field={field}
                          setValues={setValues}
                        />
                      </ContainerInp>
                    );
                  default:
                    return (
                      <ContainerInp
                        index={i}
                        key={i}
                        color={field.color}
                        fields={fields}
                        className="flex flex-col"
                      >
                        <Input field={field} />
                        <ErrorMessage
                          name={field.name}
                          component="span"
                          className="font-bold text-[16px] mt-[5px] text-red-400 mx-auto"
                        />
                      </ContainerInp>
                    );
                }
              } else {
                return null;
              }
            })}
            {isSubmitting ? (
              <Circle className="mt-4" />
            ) : (
              <>
                <div
                  className={`${
                    buttonFixed
                      ? "fixed bottom-4 right-8 w-[95%] lg:w-[45%] 2xl:w-[37%] flex gap-2 justify-end items-center"
                      : "w-full"
                  }`}
                >
                  <Button
                    onClick={() =>
                      formik.setValues((a) => ({ ...a, btnSecondary: false }))
                    }
                    type="submit"
                    className={`mt-4 py-4 ${
                      buttonFixed ? "w-full" : "w-full"
                    } ${classNameBtn}`}
                  >
                    {buttonName}
                  </Button>
                  {buttonSecondary ? (
                    <Button
                      onClick={() =>
                        formik.setValues((a) => ({ ...a, btnSecondary: true }))
                      }
                      type="submit"
                      className={`mt-4 py-4 px-6 ${
                        buttonFixed ? "w-fit" : "w-full"
                      } ${classNameBtn} bg-[#618bcc]`}
                    >
                      <i className="fas fa-share-square"></i>
                    </Button>
                  ) : null}
                </div>
                {buttonFixed ? <div className="h-16"></div> : null}
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const Input = ({ field }) => {
  const [view, setView] = useState(false);

  return field.type === "checkbox" ? (
    <div className={`flex flex-row items-center gap-x-2`}>
      <Field
        as="input"
        autoComplete="current-password"
        className="bg-white border border-gray-300 px-1 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 disabled:opacity-100 disabled:bg-gray-300"
        // className="rounded-[7px] placeholder-black placeholder:text-[15px] placeholder:opacity-50 placeholder:font-normal p-4 mt-1 font-light text-base bg-white/50"
        type={field.type}
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
        disabled={field.lockEdition}
        onWheel={(e) => e.target.blur()}
      />
      <Label htmlFor={field.name} className={field.className}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && field.type !== "checkbox" && <MessageOnLabel />}
      </Label>
    </div>
  ) : (
    <div
      className={`flex ${
        field.type === "checkbox" ? "flex-row items-center gap-x-8" : "flex-col"
      }`}
    >
      <Label htmlFor={field.name} className={field.className}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && field.type !== "checkbox" && <MessageOnLabel />}
      </Label>
      <Field
        as={
          field.type === "textArea" || field.type === "textarea"
            ? "textarea"
            : "input"
        }
        autoComplete="current-password"
        className="bg-white border border-gray-300 px-3 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 disabled:opacity-100 disabled:bg-gray-300"
        // className="rounded-[7px] placeholder-black placeholder:text-[15px] placeholder:opacity-50 placeholder:font-normal p-4 mt-1 font-light text-base bg-white/50"
        onWheel={(e) => e.target.blur()}
        type={view ? "text" : field.type}
        id={field.name}
        name={field.name}
        placeholder={field.placeholder ? field.placeholder : field.label}
        disabled={field.lockEdition}
        rows={field.rows ?? 2}
      />
      {field.type === "password" ? (
        <div
          onClick={() => {
            setView(!view);
          }}
          className="flex items-center justify-end gap-2 mt-1 mb-0 pb-0"
        >
          <label htmlFor="view-password">
            <span className="text-gray-800">Mostrar contraseña</span>
          </label>
          <input
            name="view-password"
            type="checkbox"
            onChange={() => {
              setView(!view);
            }}
            checked={view}
          />
        </div>
      ) : null}
    </div>
  );
};

const InputSerial = ({ field }) => {
  const { openDialog, dialogClose } = useDialog();

  function handleClick() {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres generar un {field.label}?</H3>}
        onClose={dialogClose}
        method="post"
        url={`/dataset/procedure/${field.id}/field/${field.fieldId}/correlative`}
        texBtn="Generar"
        handleSuccess={field.reload}
        data={{ value: field.initial }}
      />
    );
  }
  return (
    <div
      className={`flex ${
        field.type === "checkbox" ? "flex-row items-center gap-x-8" : "flex-col"
      }`}
    >
      <Label htmlFor={field.name} className={field.className}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && <MessageOnLabel />}
      </Label>
      <div className="flex items-start gap-2">
        <Field
          as={
            field.type === "textArea" || field.type === "textarea"
              ? "textarea"
              : "input"
          }
          autoComplete="current-password"
          className="w-full md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] placeholder-black placeholder:text-[15px] placeholder:opacity-50 placeholder:font-normal 
          p-4 mt-1 mb-6 font-light text-base bg-white/50 md:bg-white/75 disabled:opacity-100 disabled:bg-gray-300"
          type={field.type}
          id={field.name}
          name={field.name}
          disabled={field.lockEdition}
        />
        {!field.lockEdition ? (
          <Button onClick={handleClick} className="py-4 mt-1">
            <i className="fas fa-sort-numeric-down"></i>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const InputFile = ({ field, ...rest }) => {
  const { setFieldValue, values } = useFormikContext();
  return (
    <>
      <Label
        htmlFor={field.name}
        className={`${field.className} my-[0] mt-[0px] mb-[0px]`}
        // className={`${field.className} my-[0] mt-[25px] mb-[0px]`}
      >
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && <MessageOnLabel />}
      </Label>
      {field.initial || values[field.name] ? (
        <H3 className="font-medium my-[10px] text-[12px] sm:text-[15px] xl:text-[18px]  text-start mb-[10px]">
          Archivo subido:{" "}
          <Anchor
            className="text-[#1d4ed8]"
            href={field.initial || values[field.name]}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver archivo
          </Anchor>
        </H3>
      ) : null}
      <div
        className="md:shadow-[0_4px_4px_rgba(0,0,0,0.25)]
        rounded-[7px] placeholder-black placeholder:text-[15px]
        placeholder:opacity-50 placeholder:font-normal
        px-[17px] sm:py-[15px] font-light
        text-[15px] bg-white/50 md:bg-white/75"
      >
        <input
          {...rest}
          autoComplete="current-password"
          className="text-sm text-grey-500
          file:mr-5 file:py-2 file:px-6
          file:rounded-full file:border-0
          file:text-sm file:font-medium
          file:bg-[#1d4ed8] file:text-white
          hover:file:cursor-pointer hover:file:bg-[#1d4fd8ce]"
          type={field.type}
          id={field.name}
          name={field.name}
          placeholder={field.placeholder}
          onChange={(fileData) => {
            setFieldValue(
              field.name,
              rest.multiple ? fileData.target.files : fileData.target.files[0]
            );
          }}
        />
      </div>
    </>
  );
};

const SelectInput = ({ field }) => {
  // console.log("este es mi input: ",field)
  const { setFieldValue, values } = useFormikContext();
  const { data, loading, call } = Request({
    urlApi:
      field.typeInput === "subSelect"
        ? `${field.fatherUrl}/${field.urlApi}/${values[field.fatherName]}`
        : field.urlApi
        ? `${field.urlApi}`
        : field.urlApiDynamic
        ? `${field.urlApiDynamic(values)}`
        : null,
  });

  useEffect(() => {
    call();
  }, [field.urlApi, values[field.fatherName]]);

  function onChange(value) {
    setFieldValue(field.name, value.value);
    setFieldValue(`${field.name}Obj`, value);
  }
  function handleOption() {
    if (field.urlApi || field.urlApiDynamic) {
      if (validateArray(data)) {
        return data.map((option) => ({
          ...option,
          value: option[field.value],
          label:
            (field.labelOptionParam
              ? option[field.labelOptionParam] + ". "
              : "") + option[field.labelOption],
        }));
      }
      return [];
    } else {
      return field.options;
    }
  }
  function handlePlaceholder(value) {
    let listAux = field.urlApi ? data : field.options;
    if (value && validateArray(listAux)) {
      const OptionFind = listAux.find(
        (element) => element[field.value || "value"] === value
      );
      if (OptionFind) {
        return field.urlApi
          ? (field.labelOptionParam
              ? OptionFind[field.labelOptionParam] + ". "
              : "") + OptionFind[field.labelOption]
          : OptionFind.label;
      } else {
        return value;
      }
    }
    return "Seleccionar...";
  }

  const Text = ({ title, children }) => {
    return (
      <h3 className="whitespace-nowrap text-sm bg-white text-gray-700 px-3 py-1 rounded-md">
        <strong>{title}:</strong> {children}
      </h3>
    );
  };
  const DataOption = ({ data }) => {
    if (data) {
      if (data.type === "Texto") {
        return (
          <div className="min-w-fit mb-0.5 px-1 py-1.5 rounded-md border-[1px] text-gray border-[#cccccc] bg-[#cccccc]">
            {data.data}
          </div>
        );
        // } else if (data.type === "Aerolínea") {
      } else if (data.type === "Aerolínea") {
        const dataJson = JSON.parse(data.data);
        return (
          <div className="mt-1">
            <div className="flex flex-wrap gap-1">
              <Text title="Monto">{dataJson.amount}</Text>
              <Text title="Tipo de pago">{dataJson.pay}</Text>
              <Text title="N° de cuenta">{dataJson.account}</Text>
              <Text title="Banco">{dataJson.bank}</Text>
              <Text title="Titular de la cuenta">{dataJson.headline}</Text>
              <Text title="NIT/CI">{dataJson.citCi}</Text>
              <Text title="Correo">{dataJson.email}</Text>
              <Text title="Contacto">{dataJson.contact}</Text>
              <Text title="Días de arribo">{dataJson.day}</Text>
            </div>
          </div>
        );
      } else if (data.type === "Gasto") {
        const dataJson = JSON.parse(data.data);
        return (
          <div className="mt-1">
            <div className="flex flex-wrap gap-1">
              <Text title="Sigla">{dataJson.sigla}</Text>
              {/* <Text title="Tipo de pago">{dataJson.typeSpet}</Text> */}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  // console.log(field.label, loading);

  return (
    <div className="flex flex-col">
      <Label className="my-[20px]" htmlFor={field.name}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && <MessageOnLabel />}
      </Label>
      {validateArray(data) || validateArray(field.options) ? (
        <div className="">
          <Select
            className="w-full"
            isSearchable={true}
            onChange={onChange}
            styles={{
              placeholder: (base) => ({
                ...base,
                color: "black",
              }),
            }}
            // defaultInputValue={values[field.name]}
            placeholder={handlePlaceholder(
              values[field.aditionalName]
                ? values[field.aditionalName]
                : values[field.name]
                ? values[field.name]
                : null
            )}
            isOptionSelected={false}
            isLoading={field.urlApi ? !loading : false}
            options={handleOption()}
            isDisabled={field.lockEdition}
          />
          <DataOption
            data={
              values[field.name] && values[field.name] !== undefined && data
                ? data.filter((item) => values[field.name] === item.name)[0]
                : null
            }
          />
        </div>
      ) : !loading ? (
        <Circle />
      ) : null}
    </div>
  );
};

const SelectInputMultiple = ({ field }) => {
  const { setFieldValue, values } = useFormikContext();
  const { data, loading, call } = Request({
    urlApi:
      field.typeInput === "subSelect"
        ? `${field.fatherUrl}/${field.urlApi}/${values[field.fatherName]}`
        : field.urlApi
        ? `${field.urlApi}`
        : null,
  });

  useEffect(() => {
    call();
  }, [field.urlApi, values[field.fatherName]]);

  function onChange(value) {
    setFieldValue(field.name, value);
  }

  function handleOption() {
    if (field.urlApi) {
      if (validateArray(data)) {
        return data.map((option) => ({
          value: option[field.value],
          label:
            (field.labelOptionParam
              ? option[field.labelOptionParam] + ". "
              : "") + option[field.labelOption],
        }));
      }
      return [];
    } else {
      return field.options;
    }
  }

  function handlePlaceholder(value) {
    let listAux = field.urlApi ? data : field.options;
    if (value && validateArray(listAux)) {
      const OptionFind = listAux.find(
        (element) => element[field.value || "value"] === value
      );
      if (OptionFind) {
        return field.urlApi
          ? (field.labelOptionParam
              ? OptionFind[field.labelOptionParam] + ". "
              : "") + OptionFind[field.labelOption]
          : OptionFind.label;
      } else {
        return value;
      }
    }
    return "Seleccionar...";
  }

  return (
    <div className="flex flex-col">
      <Label className="my-[20px]" htmlFor={field.name}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && <MessageOnLabel />}
      </Label>
      <div className="">
        <Select
          className="w-full"
          isSearchable={true}
          onChange={onChange}
          styles={{
            placeholder: (base) => ({
              ...base,
              color: "black",
            }),
          }}
          isMulti
          value={values[field.name]}
          // defaultInputValue={values[field.name]}
          // placeholder={handlePlaceholder(
          //   values[field.aditionalName]
          //     ? values[field.aditionalName]
          //     : values[field.name]
          //     ? values[field.name]
          //     : null
          // )}
          isOptionSelected={false}
          isLoading={field.urlApi ? !loading : false}
          options={handleOption()}
          isDisabled={field.lockEdition}
        />
      </div>
    </div>
  );
};

const SelectNewOption = ({ field }) => {
  const { setFieldValue, values } = useFormikContext();
  const { data, loading, call } = Request({
    urlApi: field.urlApi ? `${field.urlApi}` : null,
  });
  useEffect(() => {
    call();
  }, [field.urlApi]);

  function onChange(value) {
    setFieldValue(field.name, value.value);
    setFieldValue(`${field.name}_isNew`, value.__isNew__);
  }
  function handlePlaceholder(value) {
    let listAux = field.urlApi ? data : field.options;
    if (value && validateArray(listAux)) {
      const OptionFind = listAux.find(
        (element) => element[field.value || "value"] === value
      );
      if (OptionFind) {
        return field.urlApi
          ? (field.labelOptionParam
              ? OptionFind[field.labelOptionParam] + ". "
              : "") + OptionFind[field.labelOption]
          : OptionFind.label;
      } else {
        return value;
      }
    }
    return "Seleccionar...";
  }
  function handleOption() {
    if (field.urlApi) {
      if (validateArray(data)) {
        return data.map((option) => ({
          value: option[field.value],
          label: option[field.labelOption],
        }));
      }
      return [];
    } else {
      return field.options;
    }
  }

  return (
    <div className="flex flex-col">
      <Label className="my-[20px]" htmlFor={field.name}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && <MessageOnLabel />}
      </Label>
      <CreatableSelect
        isSearchable={true}
        onChange={onChange}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        // defaultInputValue={values[field.name]}
        // placeholder={values[field.name] ? values[field.name] : 'Seleccionar...'}
        placeholder={handlePlaceholder(
          values[field.aditionalName]
            ? values[field.aditionalName]
            : values[field.name]
            ? values[field.name]
            : null
        )}
        isOptionSelected={false}
        isLoading={field.urlApi ? !loading : false}
        options={handleOption()}
        isDisabled={field.lockEdition}
      />
    </div>
  );
};

const SelectTable = ({ field }) => {
  const { setFieldValue, values } = useFormikContext();
  const [modalView, setModalView] = useState(false);
  function handleSelect(fieldTable) {
    setFieldValue(field.name, fieldTable);
    setModalView(false);
  }

  return (
    <>
      <Label htmlFor={field.name} className={field.className}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && <MessageOnLabel />}
      </Label>
      <div onClick={() => setModalView(true)} className="flex gap-2 mb-2">
        {values[field.name] ? (
          field.labelComponent(values[field.name])
        ) : (
          <div className="w-full flex justify-center items-center text-gray-400 bg-white border border-gray-300 rounded-md outline-none caret-blue-600 focus:border-blue-600 disabled:opacity-100 disabled:bg-gray-300">
            Elije una opción
          </div>
        )}
        <Button className="py-3 px-3 min-w-fit text-black">
          <i className="fas fa-search"></i>
        </Button>
      </div>
      <ModalFullScreen
        onClose={() => setModalView(false)}
        activateForm={modalView}
      >
        <H2>LISTA PARA ADICIONAR CAMPOS EXISTENTES</H2>
        <CallServiceTable
          urlApi="/field"
          header={[
            {
              name: "label",
              label: "Nombre del campo",
              filter: true,
            },
            {
              name: "edit",
              type: "action",
              label: "Añadir",
              actions: [
                {
                  label: "Añadir campo",
                  icon: "fas fa-circle-plus",
                  action: handleSelect,
                  color: "text-[#1d4ed8]",
                },
              ],
            },
          ]}
        />
      </ModalFullScreen>
    </>
  );
};

const Checkboxes = ({ field }) => {
  const { data, call } = Request({
    urlApi: field.urlApi ? `${field?.urlApi}` : null,
  });
  useEffect(() => {
    call();
  }, [field.urlApi]);

  if (field.options || field.urlApi) {
    return (
      <>
        <H2 className="mb-4">
          {field.labelCustom ? field.labelCustom : field.label}
          {field.validate && <MessageOnLabel />}
        </H2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-6">
          {field.options ? (
            <>
              {field?.options?.map((option, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center justify-between border-2 border-red-000"
                  >
                    <Label htmlFor={option.label}>{option.label}</Label>
                    <Field
                      className="cursor-pointer"
                      type="checkbox"
                      name={field.name}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {data?.map((option, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center justify-between border-2 border-red-000"
                  >
                    <Label htmlFor={option.label}>{option.label}</Label>
                    <Field
                      id={option.label}
                      className="cursor-pointer"
                      type="checkbox"
                      value={"" + option[field.value]}
                      name={field.name}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  }
};

const GenerateExcel = ({ field }) => {
  return (
    <div className="w-full my-[20px]">
      <Label className="my-[20px]" htmlFor={field.name}>
        {field.labelCustom ? field.labelCustom : field.label}
        {field.validate && <MessageOnLabel />}
      </Label>
      <div className="flex justify-between items-center gap-[10px]">
        <div
          className="md:shadow-[0_4px_4px_rgba(0,0,0,0.25)]
                  rounded-[7px] placeholder-black placeholder:text-[15px]
                  placeholder:opacity-50 placeholder:font-normal
                  px-[17px] py-2.5 font-light text-[15px] 
                  bg-white/50 md:bg-white/75 w-[100%]"
        >
          <H3 className="font-normal text-lg text-start">
            Archivo generado:{" "}
            {field.initial ? (
              <Anchor
                className="text-[#1d4ed8]"
                href={field.initial}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver archivo
              </Anchor>
            ) : (
              <span>--</span>
            )}
          </H3>
        </div>
        <Button
          className="p-2"
          type="button"
          onClick={() => field.action(field)}
        >
          <FontAwesomeIcon
            className="text-white text-xl"
            icon="fa-solid fa-file-excel"
          />
        </Button>
      </div>
    </div>
  );
};

const ContainerInp = ({ children, className, color, fields, index }) => {
  return (
    <div
      style={{
        background: `${color}31`,
        // borderRadius: `10px 10px ${
        //   fields.length > index - 1
        //     ? "10px 10px"
        //     : fields[index + 1].color === color
        //     ? "0px 0px"
        //     : "10px 10px"
        // }`,
        borderRadius: `5px`,
      }}
      className={`${className} px-1 py-2 mb-1`}
    >
      {children}
    </div>
  );
};

const SelectImage = ({ field, values, setValues }) => {
  return (
    <div
      style={{
        background: `${field.color}31`,
        // borderRadius: `10px 10px ${
        //   fields.length > index - 1
        //     ? "10px 10px"
        //     : fields[index + 1].color === color
        //     ? "0px 0px"
        //     : "10px 10px"
        // }`,
        borderRadius: `5px`,
      }}
      className={`${field.className} grid grid-cols-2 gap-2  px-1 py-2 mb-1`}
    >
      {field?.options?.map((opt, index) => (
        <div
          key={index}
          onClick={() => setValues((a) => ({ ...a, [field.name]: opt }))}
          className={`rounded-md p-1 transition-all duration-200 cursor-pointer ${
            opt?.route === values[field.name]?.route ? "bg-red-400" : ""
          }`}
        >
          <div className="flex justify-center">
            <img
              src={opt.route}
              alt=""
              className="rounded-md text-center w-52"
            />
          </div>
          <div className="text-center pt-2">
            <span>{opt.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormDefault;
