import { H3 } from "../../../components/Text";
import { Button } from "../../../components/Buttons";
import { DialogConfirmation } from "../../../components/Modal";
import { useModal } from "../../../contexts/ModalContext";
import { useDialog } from "../../../contexts/DialogContext";
import CallServiceTable from "../../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";
import { Request } from "../../../hooks/Request";
import RenderPdfPlanilla from "../../../containers/ManegeProcedure/RenderPdfPlanilla";
import { useParams } from "react-router-dom";
import RenderPdfFactura from "../../../containers/ManegeProcedure/RenderPdfFactura";

const FolderPayment = () => {

  const { procedureId } = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function createSpent(reload) {
    openModal(<FormSpent reload={reload} onClose={onClose} procedureId={procedureId} />);
  }

  function editSpent(spent, reload) {
    openModal(<FormSpent spent={spent} reload={reload} onClose={onClose} procedureId={procedureId} />);
  }

  function deleteSpent(spet, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el registro?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Expenses/${spet.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }


  function listDocumentPdfPlanilla(reload) {
    openDialog(<RenderPdfPlanilla procedureId={procedureId} reload={reload} />);
    // openDialog(<RenderPdfPlanilla />);
  }

  function listDocumentPdfFactura(reload) {
    openDialog(<RenderPdfFactura procedureId={procedureId} reload={reload} />);
    // openDialog(<RenderPdfPlanilla />);
  }

  const { data } = Request({
    urlApi: `/procedure/${procedureId}/general`,
  });


  return (
    <>
      <div className="flex flex-wrap gap-10 bg-blue-100 p-5 rounded-lg my-5">
        <p><span className="font-bold">Nro de Carpeta: </span>{ data?.nroInterno }</p>
        <p><span className="font-bold">DIM: </span>{ data?.nroDim || "---" }</p>
        <p><span className="font-bold">Cliente: </span>{ data?.companyName || "---" }</p>
        {/* <p><span className="font-bold">Referencia del Cliente: </span>{ data?.refCliente || "---" }</p> */}
      </div>
      <CallServiceTable
        addUrl={`ProcedureId=${procedureId}`}
        // onClick={viewAppointment}
        component={({ reload }) => {
            return (
              <>
               {/* <Button className="py-2" onClick={() => createCite(reload)}> */}
              <Button className="py-2 bg-[#4A75AE]" onClick={() => listDocumentPdfFactura(reload)}>
                Ver Factura
              </Button>
              <Button className="py-2 bg-[#4A75AE]" onClick={() => listDocumentPdfPlanilla(reload)}>
              {/* <Button className="py-2 bg-[#4A75AE]"> */}
                Ver Planilla
              </Button>
              <Button className="py-2" onClick={() => createSpent(reload)}>
                Adicionar
              </Button>
              </>
            );
          }}
        filters={[
          {
            name: "status",
            label: "Todos los estados",
            default: "Todos los estados",
            options: [
              {
                label: "Sin Cobrar",
                name: "Sin Cobrar",
              },
              {
                label: "Cobrado",
                name: "Cobrado",
              },
            ],
          },
          {
            name: "type",
            label: "Todos los tipos",
            default: "Todos los tipos",
            options: [
              {
                label: "Factura",
                name: "Factura",
              },
              {
                label: "Planilla",
                name: "Planilla",
              },
            ],
          },
        ]}
        urlApi="/Expenses/pag"
        header={[
          {
            label: "Sigla",
            name: "sigla",
            filter: true,
          },
          {
            label: "Fecha",
            name: "creationDate",
            type: "date"
          },
          {
            label: "Estado",
            name: "status",
            type: "custom",
            builder: (status) => {
              return (
                  <span className={`block w-fit px-2 py-1 rounded-full border ${ status==="Cobrado" ? "bg-green-100 border-green-500 text-green-500" : "bg-red-100 border-red-500 text-red-500" }`}>
                    { status }
                  </span>
              );
            },
          },
          {
            label: "Concepto",
            name: "concepto",
            filter: "true"
          },
          {
            label: "Tipo",
            name: "tipo",
          },
          {
            label: "Monto",
            name: "monto",
          },
          {
            name: "edit",
            type: "action",
            label: "Editar",
            actions: [
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: editSpent,
                color: "text-[#1d4ed8]",
              },
            ],
          },
          {
            name: "delete",
            type: "action",
            label: "Eliminar",
            actions: [
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteSpent,
                color: "text-red-500",
              },
            ],
          },
        ]}
      />
    </>
  );
};

const FormSpent = (props) => {
  const { spent, onClose, reload, procedureId } = props;


  return (
    <FormDefault
      tittle={spent ? "Editar Gasto" : "Crear nuevo Gasto"}
      fields={[        
        {
            label: "Gasto",
            name: "concepto",
            typeInput: "selectGet",
            urlApi: "/Dropdown/list-expenses/options",
            value: "name",
            labelOption: "name",
        },
        {
          label: "Tipo de Gasto",
          name: "tipo",
          type: "select",
          typeInput: "select",
          options: [
            {
              label: "Planilla",
              value: "Planilla",
            },
            {
              label: "Factura",
              value: "Factura",
            },
          ],
        },
        {
          label: "Monto",
          name: "monto",
          type: "number"
        },
      ]}
      initialValues={{
        // body: spent?.body ?? "",
        // sigla: spent?.sigla ?? "",
        // status: spent?.status ?? "",
        concepto: spent?.concepto ?? "",
        tipo: spent?.tipo ?? "Planilla",
        monto: spent?.monto ?? "",
        // procedureId: spent?.procedureId ?? "",
        // id: spent?.id ?? "",
        // procedureId: spent?.procedureId ?? ""
      }}
      validationSchema={Yup.object().shape()}
      onSubmit={async (values) => {

        let concepto = ""
        let monto = ""
        let sigla = ""
        let tipo = ""
        // console.log("valores de values: ",values)

        if(values.conceptoObj){
          // console.log("valores monto: ",values.monto)
          let dataGasto = JSON.parse(values.conceptoObj.data)
          // console.log("valores: ",dataGasto)
          // console.log("valores tipo: ",dataGasto.typeSpet)
          // console.log("valores sigla: ",dataGasto.sigla)

          concepto = values.concepto
          monto = values.monto
          sigla = dataGasto.sigla
          tipo = values.tipo
        }else{
          concepto = values.concepto
          monto = values.monto
          sigla = spent.sigla
          tipo = values.tipo
        }


        const formData = spent ? {
          body: values.body,
          sigla: sigla,
          status: spent.status,
          concepto: concepto,
          tipo: tipo,
          monto: monto,
          procedureId: procedureId,
          id: spent.id
        }
        : {
          body: null,
          sigla: sigla,
          status: "Sin Cobrar",
          concepto: concepto,
          tipo: tipo,
          monto: monto,
          procedureId: procedureId,
        }
        await requestAuth(
          spent ? "put" : "post",
          spent ? `/Expenses` : "/Expenses",
          formData
        )
          .then(() => {
            reload();
            onClose();
            toast.success("Se guardó con éxito");
          })
          .catch((response) => {
            toast.error(
              response.response.data
                ? response.response.data
                : "Se produjo un error al crear"
            );
          });
      }}
      buttonName={spent ? "Editar" : "Crear"}
    />
  );
};

export default FolderPayment;
